import React from "react";

function Buddy() {
  return (
      <div
      name="buddy"
      className="h-screen w-full bg-[#0a192f] text-gray-300 pt-[80px]" 
      >
        <div className="w-full bg-[#0a192f] text-center py-8">
        <h1 className="text-4xl font-bold text-cyan-500">Buddy</h1>
        </div>

        <div className="w-full lg:w-[60%] text-lg lg:text-xl text-center leading-relaxed">
        <p>Coming soon!</p>
        </div>

      </div>
    );

}
export default Buddy;